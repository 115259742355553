<template>
  <div class="booking-date-widget">
    <div class="range-display-component">
      <div class="boxed-icon green">
        <i class="icon-calendar"></i>
      </div>
      <div class="start-date" @click="toggleModal">
        <span v-if="!selectedDate.start">{{ trans('common.start_date') }}</span>
        <span v-if="selectedDate.start">{{ dateFormat(selectedDate.start) }}</span>
      </div>
      <svg viewBox="0 0 330 330">
          <path d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z" />
      </svg>
      <div class="end-date" :class="{ active: selectedDate.end }" @click="toggleModal">
        <span v-if="!selectedDate.end">{{ trans('common.end_date') }}</span>
        <span v-if="selectedDate.end">{{ dateFormat(selectedDate.end) }}</span>
      </div>
      <div class="btn-clear" @click="clearSelection" :class="{ hidden: !this.selectedDate.start && !this.selectedDate.end }">
        <i class="icon-close"></i>
      </div>
    </div>
    <div class="modal-component" v-click-away="this.modalOpenState ? toggleModal : null" :class="{ hidden: !this.modalOpenState }">
      <DatePicker v-model="selectedDate"
                  :attributes="this.attrs"
                  :min-date="this.minDate"
                  :max-date="this.maxDate"
                  :disabled-dates="this.disabledDates"
                  :drag-attribute="this.selectedAttr"
                  :select-attribute="this.selectedAttr"
                  is-range
                  :model-config="this.modelConfig"
                  mode="range"
                  @dayclick="dayClickEvent"
                  @drag="dragEvent"
                  locale="nl"
      />
    </div>
    <div v-if="this.checkInTimeName.length > 0 && this.checkOutTimeName.length > 0" class="row mt-4 check-in-out-times">
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ trans('common.check-in-time') }}</label>
          <Multiselect
              :placeholder="trans('common.select')"
              :required="true"
              :disabled="!this.checkInTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="this.checkInTimeOptions"
              v-model="this.selectedCheckInTime"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ trans('common.check-out-time') }}</label>
          <Multiselect
              :placeholder="trans('common.select')"
              :required="true"
              :disabled="!this.checkOutTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="this.checkOutTimeOptions"
              v-model="this.selectedCheckOutTime"
          />
        </div>
      </div>
    </div>
  </div>
  <input type="hidden" :name="checkInTimeName" :value="selectedCheckInTime">
  <input type="hidden" :name="checkOutTimeName" :value="selectedCheckOutTime">
  <input type="hidden" class="js_fetch-booking-data" id="create_option_checkInDate" :name="startName" :value="selectedDate.start ? dateFormat(selectedDate.start) : ''">
  <input type="hidden" class="js_fetch-booking-data" id="create_option_checkOutDate" :name="endName" :value="selectedDate.end ? dateFormat(selectedDate.end) : ''">
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
import mixins from '../mixins.js'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Calendar,
    DatePicker,
    Multiselect
  },
  mixins: [mixins],
  props: [
    'translations',
    'startName',
    'endName',
    'checkInDate',
    'checkOutDate',
    'checkInTimeName',
    'checkOutTimeName',
    'checkInTimeOptionsJson',
    'checkOutTimeOptionsJson',
    'bookedDatesJson',
    'bookingSlotsJson',
    'reverseDate',
  ],
  data() {
    return {
      modalOpenState: false,
      tmp: null,
      modelConfig: {
        timeAdjust: '12:00:00',
      },
      bookedDates: [],
      bookingSlots: [],
      selectedAttr: {
        highlight: {
          contentClass: 'selected-content',
          class: 'selected'
        }
      },
      attrs: [],
      selectedDate: {
        start: this.checkInDate ? new Date(this.checkInDate) : null,
        end: this.checkInDate ? new Date(this.checkOutDate) : null
      },
      minDate: new Date(Date.now()),
      maxDate: null,
      disabledDates: [],
      checkInTimeOptions: [],
      checkOutTimeOptions: [],
      checkInTime: null,
      checkOutTime: null,
      selectedCheckInTime: null,
      selectedCheckOutTime: null,
    };
  },
  mounted() {
    this.initFormListeners();

    if(this.reverseDate) {
      this.minDate = null;
      this.maxDate = new Date(Date.now());
    }

    this.bookingSlots = JSON.parse(this.bookingSlotsJson).map(bookingSlot =>
        {
          return {
            start: new Date(bookingSlot.start),
            end: new Date(bookingSlot.end)
          }
        }
    );

    this.bookedDates = JSON.parse(this.bookedDatesJson).map(bookedDate =>
        {
          return {
            start: new Date(bookedDate.start),
            end: new Date(bookedDate.end)
          }
        }
    );

    this.setCheckInOutTimeSelectors(JSON.parse(this.checkInTimeOptionsJson), JSON.parse(this.checkOutTimeOptionsJson));
    this.setDisabledStateOnBookedDates();
    this.setStylingOnBookedDates();
  },
  watch: {
    selectedDate (val) {
      this.$nextTick(async() => {
        if (this.selectedDate.start && this.selectedDate.end) {
          const renewedReservationData = await window.fetchReservationData();

          if (renewedReservationData && renewedReservationData.hasOwnProperty('checkInTimes') && renewedReservationData.hasOwnProperty('checkOutTimes')) {
            this.setCheckInOutTimeSelectors(renewedReservationData.checkInTimes, renewedReservationData.checkOutTimes);
          }
        }
      });
    }
  },
  methods: {
    initFormListeners() {
      const reservationGroupElement = document.querySelector('#create_option_group');
      const reservationCapacityElement = document.querySelector('#create_option_amountOfPersons');

      if(reservationGroupElement) {
        reservationGroupElement.addEventListener('change', (event) => {
          this.fetchData();
        });
      }

      if(reservationCapacityElement) {
        let timer;
        const waitTime = 500;

        reservationCapacityElement.addEventListener('input', (event) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            this.fetchData();
          }, waitTime);
        });
      }
    },
    async fetchData() {
      if (this.selectedDate.start && this.selectedDate.end) {
        const renewedReservationData = await window.fetchReservationData();

        if (renewedReservationData && renewedReservationData.hasOwnProperty('checkInTimes') && renewedReservationData.hasOwnProperty('checkOutTimes')) {
          this.setCheckInOutTimeSelectors(renewedReservationData.checkInTimes, renewedReservationData.checkOutTimes);
        }
      }
    },
    dateFormat(date) {
      const d = date.getDate();
      const m = date.getMonth() + 1; //Month from 0 to 11
      const y = date.getFullYear();
      return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
    },
    clearSelection () {
      this.selectedCheckInTime = null;
      this.selectedCheckOutTime = null;

      if(this.reverseDate) {
        this.minDate = null;
        this.maxDate = new Date(Date.now());
      } else {
        this.minDate = new Date(Date.now());
        this.maxDate = null;
      }

      this.disabledDates = this.bookedDates;
      this.selectedDate = {
        start: null,
        end: null
      }
      
      this.$nextTick(() => {
        this.checkInTimeOptions = [];
        this.checkOutTimeOptions = [];
      });
    },
    toggleModal() {
      if (this.selectedDate.start && this.selectedDate.end) {
        return;
      }
      this.modalOpenState = !this.modalOpenState;
    },
    dayClickEvent(e) {
      // check if selection is within slot borders, then clear selection

      this.bookingSlots.forEach(bookingSlot => {
        if(this.selectedDate.end > bookingSlot.start && this.selectedDate.end < bookingSlot.end) {
          this.selectedDate.end = bookingSlot.end;
        }

        if(this.selectedDate.start > bookingSlot.start && this.selectedDate.start < bookingSlot.end) {
          this.selectedDate.start = bookingSlot.start;
        }
      })

      // remove selection when clicking double
      if (e.range.start.toDateString() === this.tmp) {
          this.clearSelection()
      }
      this.tmp = e.range.start.toDateString();

      if (this.selectedDate.start && this.selectedDate.end) {
        this.modalOpenState = false
      }
    },
    dragEvent(e) {
      // catch exception when you get stuck in booking start date
      if(this.bookedDates.map(booked => booked.start.toDateString()).includes(e.start.toDateString())) {
        console.info('exception cannot start from booking start');
        return;
      }

      // when selecting cap the max date to the next booking
      let nextAvailableDate = null;
      this.bookedDates.some(bookedDate => {
        if (bookedDate.start >= e.start) {
          nextAvailableDate = bookedDate;
        }
        return bookedDate.start >= e.start;
      })
      
      this.disabledDates = null;
      this.minDate = e.start;
      if (nextAvailableDate) {
        this.maxDate = nextAvailableDate.start;
      }
    },
    setStylingOnBookedDates() {
      const startDates = this.bookedDates.map(booking => {
        return {
          highlight: {
            contentClass: 'booking-start-date',
            class: 'booking-start-date'
          },
          popover: {
            label: trans('common.end_date'),
            hideIndicator: true,
          },
          dates: booking.start
        }
      });

      const booked = this.bookedDates.map(booking => {
        return {
          highlight: {
            contentClass: 'booked-date',
            class: 'booked-date'
          },
          dates: {start: booking.start, end: booking.end}
        }
      });

      const bookingSlotsStarts = this.bookingSlots.map(bookingSlot => {
        return {
          highlight: {
            contentClass: 'booking-slot-date-start',
            class: 'booking-slot-date-start'
          },
          dates: bookingSlot.start
        }
      });

      const bookingSlotsEnds = this.bookingSlots.map(bookingSlot => {
        return {
          highlight: {
            contentClass: 'booking-slot-date-end',
            class: 'booking-slot-date-end'
          },
          dates: bookingSlot.end
        }
      });

      const bookingSlotDays = this.bookingSlots.filter(bookingSlot => {
          const startDate = new Date(bookingSlot.start)
          return startDate.setDate(startDate.getDate() + 1) < bookingSlot.end
      }).map(bookingSlot => {
        const startDate = new Date(bookingSlot.start)
        const endDate = new Date(bookingSlot.end)

        return {
            highlight: {
              contentClass: 'booking-slot-date',
              class: 'booking-slot-date'
            },
            popover: {
              label: `${trans('reservations.booking_slot_popup_message')} ${this.dateFormat(bookingSlot.start)} - ${this.dateFormat(bookingSlot.end)}`,
              hideIndicator: true,
            },
            dates: {start: startDate.setDate(startDate.getDate() + 1), end: endDate.setDate(endDate.getDate() - 1)}
          }
      });
      this.attrs = [ ...booked, ...bookingSlotDays, ...bookingSlotsStarts, ...bookingSlotsEnds, ...startDates];
    },
    setDisabledStateOnBookedDates() {
      this.disabledDates = this.bookedDates.map(bookedPeriod => {
        let endDateMinusLastDay = bookedPeriod.end.setDate(bookedPeriod.end.getDate() - 1);

        if(endDateMinusLastDay <= bookedPeriod.end) {
          endDateMinusLastDay = bookedPeriod.end
        }

        return {
          start: bookedPeriod.start,
          end: endDateMinusLastDay
        }
      })
    },
    setCheckInOutTimeSelectors(checkInTimeData, checkOutTimeData) {
      this.checkInTimeOptions = Object.entries(checkInTimeData).map(([key, value]) => {
        return {value: key, label: value}
      });

      this.checkOutTimeOptions  = Object.entries(checkOutTimeData).map(([key, value]) => {
        return {value: key, label: value}
      });
    }
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>
