<template>
  <div>
    <table>
      <tr>
        <th>
          <h5>{{ trans('common.general_prices') }} ({{ this.year }})</h5>
        </th>
        <th v-if="hasPricesSelfCooking">{{ trans('common.self_cooking') }} <span>({{ trans(`common.${priceTypeSelfCooking}`)}})</span></th>
        <th v-if="hasPricesFullBoard">{{ trans('common.full_board') }}* <span>({{ trans('common.price_per_person_per_day') }})</span></th>
      </tr>
      <tr v-for="price in this.prices">
        <td><b>{{ price.name }}</b></td>
        <td v-if="hasPricesSelfCooking">{{ price.price_with_currency_self_cooking }} <span v-if="price.minimum_guests_self_cooking">- (min. {{ price.minimum_guests_self_cooking }} pers.)</span></td>
        <td v-if="hasPricesFullBoard">{{ price.price_with_currency_full_board }} <span v-if="price.minimum_guests_full_board"> - (min. {{ price.minimum_guests_full_board }} pers.)</span></td>
      </tr>
    </table>
    <table v-if="this.exceptions.length">
      <tr>
        <th>
          <h5>{{ trans('common.exceptions') }}</h5>
        </th>
        <th v-if="hasExceptionSelfCooking">{{ trans('common.self_cooking') }} <span>({{ trans(`common.${priceTypeSelfCooking}`)}})</span></th>
        <th v-if="hasExceptionFullBoard">{{ trans('common.full_board') }}* <span>({{ trans('common.price_per_person_per_day') }})</span></th>
      </tr>
      <tr v-for="exception in this.exceptions">
        <td><b>{{ exception.name }}</b> ({{ exception.unit }})</td>
        <td v-if="hasExceptionSelfCooking">{{ exception.price_with_currency_self_cooking }}</td>
        <td v-if="hasExceptionFullBoard">{{ exception.price_with_currency_full_board }}</td>
      </tr>
    </table>

    <p v-if="externalUrl" class="mt-3">
      <a :href="externalUrl" target="__blank">{{ trans('common.full_board_price_link_label') }} ({{ this.year }})</a>
    </p>
<!--    <table v-if="this.warranties.length">-->
<!--      <tr>-->
<!--        <th>-->
<!--          <h5>{{ trans('common.warranty') }}</h5>-->
<!--        </th>-->
<!--        <th v-if="hasWarrantySelfCooking">{{ trans('common.self_cooking') }}</th>-->
<!--        <th v-if="hasWarrantyFullBoard">{{ trans('common.full_board') }}* </th>-->
<!--      </tr>-->
<!--      <tr v-for="warranty in this.warranties">-->
<!--        <td><b>{{ warranty.name }}</b></td>-->
<!--        <td v-if="hasWarrantySelfCooking">{{ warranty.price_with_currency_self_cooking }}</td>-->
<!--        <td v-if="hasWarrantyFullBoard">{{ warranty.price_with_currency_full_board }}</td>-->
<!--      </tr>-->
<!--    </table>-->
  </div>
</template>

<script>
import mixins from '../../../mixins.js'

const isFilteredArrayFilled = (array, value) => {
  return array.filter(e => e[value]).length > 0;
}

export default {
  name: "GeneralPricesDesktop",
  mixins: [mixins],
  props: [
    'year',
    'warranties',
    'exceptions',
    'prices',
    'priceTypeSelfCooking',
    'externalUrl'
  ],
  computed: {
    hasPricesFullBoard() { return isFilteredArrayFilled(this.prices, 'price_full_board') },
    hasPricesSelfCooking() { return isFilteredArrayFilled(this.prices, 'price_self_cooking') },
    hasExceptionFullBoard() { return isFilteredArrayFilled(this.exceptions, 'price_full_board') },
    hasExceptionSelfCooking() { return isFilteredArrayFilled(this.exceptions, 'price_self_cooking') },
    hasWarrantyFullBoard() { return isFilteredArrayFilled(this.warranties, 'price_full_board') },
    hasWarrantySelfCooking() { return isFilteredArrayFilled(this.warranties, 'price_self_cooking') },
  }
}
</script>

<style scoped>

</style>
