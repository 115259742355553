<template>
  <Splide :options="{ rewind: true, autoplay: true, lazyLoad: 'nearby' }">
    <SplideSlide v-for="(image, index) in images" :key="index">
      <img :data-splide-lazy="image" />
    </SplideSlide>
    <template v-if="showLabels">
      <div class="carousel-labels">
          <span v-if="showAccreditation" class="prop-group">
            <span v-for="(accreditationLogo, index) in accreditationLogosArray" :key="index" class="prop-group">
              <img :src="accreditationLogo" :alt="accreditationType" />
            </span>
          </span>
          <a v-if="showIvv" :href="this.ivvLink" class="prop-group" target="_blank" rel="noopener" :title="trans('common.member_of_ivv')">
            <img :src="this.ivvLogo" :alt="trans('common.member_of_ivv')" />
          </a>
      </div>
    </template>
    <div class="carousel-props">
      <template v-if="showStats">
        <strong>{{ trans('common.capacity') }}</strong>
        <span class="prop-group tooltip" :data-tooltip="trans('common.capacities.total_capacity')">
          <i class="icon-people"></i>
          {{ this.capacity }}
        </span>
        <span class="prop-group tooltip" :data-tooltip="trans('common.capacities.beds')">
          <i class="icon-bed"></i>
          {{ this.beds }}
        </span>
          <span class="prop-group tooltip" :data-tooltip="trans('common.capacities.own_beds')">
          <i class="icon-sleeping-bag"></i>
          {{ this.ownBeds }}
        </span>
          <span class="prop-group tooltip" :data-tooltip="trans('common.capacities.max_capacity_in_tent')">
          <i class="icon-tent"></i>
          {{ this.tents }}
        </span>
      </template>
      <div class="share cursor-pointer" @click="copyUrl">
        <i class="icon-share"></i>
        {{ trans('common.share') }}
      </div>
    </div>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import mixins from '../mixins.js'
import {array, string} from "prop-types";
export default {
  name: 'GalleryWidget',
  mixins: [mixins],
  props: [
    'translations',
    'jsonImages',
    'capacity',
    'beds',
    'ownBeds',
    'tents',
    'memberIvv',
    'ivvLogo',
    'ivvLink',
    'accreditationType',
    'accreditationLogos',
  ],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      images: JSON.parse(this.jsonImages),
      accreditationLogosArray: JSON.parse(this.accreditationLogos),
    }
  },
  computed: {
    showStats() {
      return this.capacity || this.beds || this.ownBeds || this.tents
    },
    showIvv() {
      return this.memberIvv === 'true' || this.memberIvv === '1' || this.memberIvv === 1
    },
    showAccreditation() {
      return this.accreditationType !== ''
    },
    showLabels() {
      return this.showIvv || this.showAccreditation
    }
  },
  mounted() {
    document.querySelector('.splide__arrow--prev').innerHTML = `<i class="icon-arrow-left"><span class="path1"></span><span class="path2"></span></i>`
    document.querySelector('.splide__arrow--next').innerHTML = `<i class="icon-arrow-right"><span class="path1"></span><span class="path2"></span></i>`
    const carouselPropsEl = document.querySelector('.carousel-props');
    const carouselLabelsEl = document.querySelector('.carousel-labels');
    carouselPropsEl.parentNode.parentNode.append(carouselPropsEl);
    carouselLabelsEl.parentNode.parentNode.append(carouselLabelsEl);
  },
  methods: {
    copyUrl() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(window.location.href);
        Toastify({
          ...ToastOptions,
          text: trans('messages.copied_url'),
          className: "success",
        }).showToast();
      } else {
        console.info('Copying to clipboard failed, context is not secure.');
      }
    }
  }
};
</script>

<style lang="scss" module>

</style>