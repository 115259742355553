<template>
  <Calendar locale="nl" :min-date="getMinDate" :max-date="getMaxDate" :attributes="attrs" :from-page="getFromPageDate" />
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
import mixins from '../mixins.js'

export default {
  props: [
    'translations',
    'jsonDates',
    'jsonYears',
    'fromPageDate',
  ],
  mixins: [mixins],
  components: {
    Calendar,
    DatePicker,
  },
  data() {
    return {
      dates: JSON.parse(this.jsonDates),
      years: Object.values(JSON.parse(this.jsonYears)),
      attrs: [],
    };
  },
  mounted() {
    this.attrs = this.dates.map((date) =>{
      return {
        highlight: {
          class: date.class,
          contentClass: date.class,
          fillMode: 'solid',
        },
        dates: new Date(date.year, date.month - 1, date.day)
      }
    })
  },
  computed: {
    getMaxDate() {
      return new Date(Math.max(...this.years),11, 31);
    },
    getMinDate() {
      return new Date(Math.min(...this.years), 0, 1)
    },
    getFromPageDate() {
      if (!this.fromPageDate) {
          return null;
      }

      const dateParts = this.fromPageDate.split("/");

      return {
        month: parseInt(dateParts[1]),
        year: parseInt(dateParts[2]),
      }
    },
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>
