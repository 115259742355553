<template>
  <div class="search-accommodation-component" :class="{'isLoading': store.showLoader}">
    <div class="img-wrapper">
      <picture>
        <img :src="result.cover_image" alt="">
      </picture>
    </div>
    <div class="content">
      <div class="general-information">
        <a :href="this.store.generateDomainUrl(result.slug)" class="mb-3 d-block">
          <h4>{{ result.name }}</h4>
          <small>
            <i class="icon-location mr-1"></i>
            {{ result.address.city }}
          </small>
        </a>
        <div class="description" v-html="renderDescription"></div>

        <span v-if="result.book_online" class="badge green d-inline-flex mb-3">{{ trans('common.book_online') }}</span>
      </div>

      <div class="listings scrollable d-none d-md-block">
        <a v-for="listing in result.accommodations"
           :href="this.store.generateAccommodationUrl(listing.slug)"
           :key="listing.id"
           class="listing"
        >
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              <i v-if="listing.combination" class="icon-combo mr-2"></i>
              <span class="name">
                {{ listing.name }}
              </span>
            </div>
            <i class="icon-arrow-right">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>

          <div class="stats-wrapper">
            <div class="stats-group">
              <span class="stat tooltip" :data-tooltip="trans('common.capacities.total_capacity')">
                <i class="icon-people"></i>
                {{ listing.total_capacity }}
              </span>
              <span class="stat tooltip" :data-tooltip="trans('common.capacities.beds')">
                <i class="icon-bed"></i>
                {{ listing.beds_capacity }}
              </span>
              <span class="stat tooltip" :data-tooltip="trans('common.capacities.own_beds')">
                <i class="icon-sleeping-bag"></i>
                {{ listing.own_beds_capacity }}
              </span>
              <span class="stat tooltip" :data-tooltip="trans('common.capacities.max_capacity_in_tent')">
                <i class="icon-tent"></i>
                {{ listing.tent_capacity }}
            </span>
            </div>
            <div class="stats-group">
              <span v-for="priceTag in listing.from_prices" class="stat tooltip" :data-tooltip="trans(`common.${priceTag.meal_type}`)">
                <i :class="{'icon-stove': priceTag.meal_type === 'self_cooking', 'icon-meal': priceTag.meal_type === 'full_board'}"></i>
                <div class="pricetag">
                  {{ trans('common.price_from') }} €{{ toCurrency(priceTag.amount) }}
                  <small v-if="priceTag.meal_type === 'self_cooking' && priceTag.price_type === 'per_night'">{{ trans('common.price_types.per_night')}}</small>
                  <small v-if="priceTag.meal_type === 'self_cooking' && priceTag.price_type === 'per_person_per_night'">{{ trans('common.price_types.per_person_per_night')}}</small>
                  <small v-if="priceTag.meal_type === 'full_board'">{{ trans('common.price_types.per_person_per_stay')}}</small>
                </div>
              </span>
            </div>
          </div>
        </a>
      </div>

      <div class="mobile-listing-panel d-flex d-md-none" :class="{'is-active': this.panelState}">
        <button class="mobile-listing-panel-trigger cursor-pointer" @click="this.panelState = !this.panelState">
          <div>
            <i v-if="this.panelState" class="icon-close"></i>
            <i v-if="!this.panelState" class="icon-arrow-right">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
        </button>
        <div class="mobile-listing-panel-content">
          <div class="listings scrollable">
            <a v-for="listing in result.accommodations"
               :href="this.store.generateAccommodationUrl(listing.slug)"
               :key="listing.id"
               class="listing"
            >
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <i v-if="listing.combination" class="icon-combo mr-2"></i>
                  <span class="name">
                {{ listing.name }}
              </span>
                </div>
                <i class="icon-arrow-right">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
              </div>

              <div class="stats-wrapper">
                <div class="stats-group">
              <span class="stat">
                <i class="icon-people"></i>
                {{ listing.total_capacity }}
              </span>
                  <span class="stat">
                <i class="icon-bed"></i>
                {{ listing.beds_capacity }}
              </span>
                  <span class="stat">
                <i class="icon-sleeping-bag"></i>
                {{ listing.own_beds_capacity }}
              </span>
                  <span class="stat">
                <i class="icon-tent"></i>
                {{ listing.tent_capacity }}
            </span>
                </div>
                <div class="stats-group">
              <span v-for="priceTag in listing.from_prices" class="stat">
                <i :class="{'icon-stove': priceTag.meal_type === 'self_cooking', 'icon-meal': priceTag.meal_type === 'full_board'}"></i>
                <div class="pricetag">
                  {{ trans('common.price_from') }} €{{ toCurrency(priceTag.amount) }}
                  <small v-if="priceTag.meal_type === 'self_cooking' && priceTag.price_type === 'per_night'">{{ trans('common.price_types.per_night')}}</small>
                  <small v-if="priceTag.meal_type === 'self_cooking' && priceTag.price_type === 'per_person_per_night'">{{ trans('common.price_types.per_person_per_night')}}</small>
                  <small v-if="priceTag.meal_type === 'full_board' && priceTag.price_type === 'per_person_per_night'">{{ trans('common.price_types.per_person_per_stay')}}</small>
                </div>
              </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <a href="#" class="show-accommodation-on-map" @click="showResultOnMap">
      <i class="icon-location"></i>
    </a>
    <div v-if="store.showLoader" class="loading-overlay">
      <Loader />
    </div>
  </div>
</template>

<script>
import mixins from '../../../mixins.js'
import { store } from "../../../store";
import Loader from "../../Loader";

export default {
  name: "ResultCard",
  components: {Loader},
  mixins: [mixins],
  props: {
    result: {},
  },
  data() {
    return {
      store,
      panelState: false
    }
  },
  computed: {
    renderDescription() {
      if(!this.result.description) {
        return ''
      }

      return this.result.description.replace( /(<([^>]+)>)/ig, '');
    }
  },
  methods: {
    showResultOnMap() {
      this.$emit('update-view-mode-with-focus', this.result)
    },
    toCurrency(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
}
</script>

<style scoped>

</style>
