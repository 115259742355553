<template>
  <div class="booking-date-widget">
    <div class="range-display-component">
      <div class="boxed-icon green">
        <i class="icon-calendar"></i>
      </div>
      <div class="start-date" @click="toggleModal">
        <span v-if="!selectedDate.start">{{ trans('common.start_date') }}</span>
        <span v-if="selectedDate.start">{{ dateFormat(selectedDate.start) }}</span>
      </div>
      <svg viewBox="0 0 330 330">
        <path d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z" />
      </svg>
      <div class="end-date" :class="{ active: selectedDate.end }" @click="toggleModal">
        <span v-if="!selectedDate.end">{{ trans('common.end_date') }}</span>
        <span v-if="selectedDate.end">{{ dateFormat(selectedDate.end) }}</span>
      </div>
      <div class="btn-clear" @click="clearSelection" :class="{ hidden: !this.selectedDate.start && !this.selectedDate.end }">
        <i class="icon-close"></i>
      </div>
    </div>
    <div class="modal-component" v-click-away="this.modalOpenState ? toggleModal : null" :class="{ hidden: !this.modalOpenState }">
      <DatePicker v-model="selectedDate"
                  :attributes="this.attrs"
                  :min-date="this.minDate"
                  :max-date="this.maxDate"
                  is-range
                  mode="range"
                  @dayclick="dayClickEvent"
                  locale="nl"
      />
    </div>
    <div v-if="this.checkInTimeName.length > 0 && this.checkOutTimeName.length > 0" class="row mt-4 check-in-out-times">
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ trans('common.check-in-time') }}</label>
          <Multiselect
              :placeholder="trans('common.select')"
              :required="true"
              :disabled="!this.checkInTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="this.checkInTimeOptions"
              v-model="this.selectedCheckInTime"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-4">
          <label>{{ trans('common.check-out-time') }}</label>
          <Multiselect
              :placeholder="trans('common.select')"
              :required="true"
              :disabled="!this.checkOutTimeOptions.length"
              class="form-control form-control-lg form-control-solid"
              :options="this.checkOutTimeOptions"
              v-model="this.selectedCheckOutTime"
          />
        </div>
      </div>
    </div>
  </div>
  <input type="hidden" :name="checkInTimeName" :value="selectedCheckInTime">
  <input type="hidden" :name="checkOutTimeName" :value="selectedCheckOutTime">
  <input type="hidden" :name="startName" :value="selectedDate.start ? dateFormat(selectedDate.start) : ''">
  <input type="hidden" :name="endName" :value="selectedDate.end ? dateFormat(selectedDate.end) : ''">
</template>

<script>
import { DatePicker } from 'v-calendar';
import mixins from '../mixins.js'
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    DatePicker,
    Multiselect
  },
  mixins: [mixins],
  props: [
    'translations',
    'startName',
    'endName',
    'checkInTimeName',
    'checkOutTimeName',
    'checkInTimeOptionsJson',
    'checkOutTimeOptionsJson',
  ],
  data() {
    return {
      modalOpenState: false,
      selectedDate: {
        start: null,
        end: null
      },
      minDate: new Date(),
      maxDate: null,
      selectedCheckInTime: null,
      selectedCheckOutTime: null,
      checkInTimeOptions: [],
      checkOutTimeOptions: [],
      attrs: [],
    };
  },
  mounted() {
    this.setCheckInOutTimeSelectors(JSON.parse(this.checkInTimeOptionsJson), JSON.parse(this.checkOutTimeOptionsJson));
  },
  methods: {
    toggleModal() {
      if (this.selectedDate.start && this.selectedDate.end) {
        return;
      }
      this.modalOpenState = !this.modalOpenState;
    },
    dateFormat(date) {
      const d = date.getDate();
      const m = date.getMonth() + 1; //Month from 0 to 11
      const y = date.getFullYear();
      return '' + (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
    },
    clearSelection () {
      this.selectedCheckInTime = null;
      this.selectedCheckOutTime = null;
      this.selectedDate = {
        start: null,
        end: null
      }
      this.modalOpenState = false;
      this.$nextTick(() => {
        this.checkInTimeOptions = [];
        this.checkOutTimeOptions = [];
      });
    },
    dayClickEvent(e) {
      // remove selection when clicking double
      if (e.range.start.toDateString() === this.tmp) {
        this.clearSelection()
      }
      this.tmp = e.range.start.toDateString();

      if (this.selectedDate.start && this.selectedDate.end) {
        this.modalOpenState = false
      }
    },
    setCheckInOutTimeSelectors(checkInTimeData, checkOutTimeData) {
      this.checkInTimeOptions = Object.entries(checkInTimeData).map(([key, value]) => {
        return {value: value, label: value}
      });

      this.checkOutTimeOptions  = Object.entries(checkOutTimeData).map(([key, value]) => {
        return {value: value, label: value}
      });
    }
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>
