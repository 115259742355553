<template>
  <div class="container accommodation-search-widget pt-5">
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
          <div>
            <h3 v-if="!store.init">
            <span class="text-green">
              {{ store.totalResultsCount }}
            </span>
              &nbsp;<span v-text="store.results.length > 1 ? trans('common.possibilities') : trans('common.possibility')"></span>
            </h3>
          </div>

          <sorting/>
        </div>
      </div>
      <div v-if="store.init" class="col-12 d-flex justify-content-center">
        <Loader />
      </div>
      <div v-if="!store.init" class="col-12 col-lg-3">
          <facet-list />
      </div>
      <div v-if="!store.init" class="col-12 col-lg-9">
        <map-view
            v-if="this.store.viewMode === this.viewModes.MAP && store.mapMarkers.length"
            :results="store.mapMarkers"
            :focus-on-domain="focusOnDomain"
        ></map-view>
        <list-view
            v-if="this.store.viewMode === this.viewModes.LIST && store.results.length"
            :results="store.results"
            :pager="store.pager"
            @update-view-mode-with-focus="toggleMapModeAndFocus"
            @go-to-page="goToPage"
        />
        <div v-if="0 === this.store.results.length" class="no-results">
          <div>{{ trans('common.no_domain_results') }}</div>
          <a :href="getDefaultUrl">{{ trans('common.show_all_results') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacetList from "../components/_segments/AccommodationSearchWidget/FacetList";
import Sorting from "../components/_segments/AccommodationSearchWidget/Sorting";
import MapView from "../components/_segments/AccommodationSearchWidget/MapView";
import ListView from "../components/_segments/AccommodationSearchWidget/ListView";
import Loader from "../components/Loader";
import mixins from '../mixins.js'
import { store } from '../store.js'
import { viewModes } from "../variables";

export default {
  name: "AccommodationSearchWidget",
  components: {Loader, ListView, MapView, Sorting, FacetList},
  mixins: [mixins],
  props: [
    'translations',
    'domainUrl',
    'accommodationUrl',
    'resultsJson',
    'pagerJson',
    'contextJson',
    'facetsJson',
  ],
  data() {
    return {
      store,
      viewModes,
    }
  },
  mounted() {
    const initContext = JSON.parse(this.contextJson);

    this.store.domainUrlTemplate = this.domainUrl;
    this.store.accommodationUrlTemplate = this.accommodationUrl;

    if (initContext) {
      this.store.context = initContext;
    }

    this.store.updateSearchResults()
  },
  methods: {
    goToPage(index) {
      this.store.page = index;
      this.store.updateSearchResults();
    },
    toggleMapModeAndFocus(result) {
      this.store.viewMode = this.viewModes.MAP;
      this.store.focusOnDomain = result;
    },
  },
  computed: {
    getDefaultUrl() {
      return location.protocol + '//' + location.host + location.pathname;
    }
  }
}
</script>

<style scoped>

</style>
